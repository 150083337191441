import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest, distinct, filter, map, take, takeUntil } from 'rxjs';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private isDestroyed = new Subject();

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable().pipe(distinct(), takeUntil(this.isDestroyed));

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable().pipe(distinct(), takeUntil(this.isDestroyed));
  public loaded$ = this.isDoneLoading$.pipe(
    filter((done) => done),
    take(1)
  );

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(
    map((values) => values.every((b) => b)),
    takeUntil(this.isDestroyed)
  );

  private navigateToLoginPage(loginPath: string = '') {
    this.router.navigateByUrl(loginPath);
  }

  updateQueryParams(params: { [key: string]: string }, replace: boolean = false) {
    if(replace) {
      this.oauthService.customQueryParams = params;
    } else {
      this.oauthService.customQueryParams = { ...this.oauthService.customQueryParams, ...params };
    }
  }

  constructor(private oauthService: OAuthService, private router: Router) {
    // Useful for debugging:
    this.oauthService.events.pipe(takeUntil(this.isDestroyed)).subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.debug('OAuthEvent Object:', event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.pipe(takeUntil(this.isDestroyed)).subscribe(() => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error', ''].includes(e.type)),
        takeUntil(this.isDestroyed)
      )
      .subscribe(() => this.navigateToLoginPage());

    this.oauthService.events
      .pipe(
        filter((e) => ['token_revoke_error', 'token_refresh_error'].includes(e.type)),
        takeUntil(this.isDestroyed)
      )
      .subscribe(() => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  ngOnDestroy() {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
  }

  /**
   *
   * @param skipInitialRefreshToken se passato a true, permette di navigare alla pagina di login
   */
  public async runInitialLoginSequence(skipInitialRefreshToken: boolean = false): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(
        location.hash
          .substring(1)
          .split('&')
          .map((kvp) => kvp.split('='))
      );
    }

    try {
      // 0. LOAD CONFIG:
      // First we have to check to see how the IdServer is
      // currently configured:
      await this.oauthService.loadDiscoveryDocument();

      // For demo purposes, we pretend the previous call was very slow
      //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))
      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      await this.oauthService.tryLogin();
      if ((!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) && !skipInitialRefreshToken) {
        // return;
        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        await this.oauthService.refreshToken();
      }

      try {
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigate([stateUrl]);
        }
      } catch (result: any) {
        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
        // Only the ones where it's reasonably sure that sending the
        // user to the IdServer will help.
        const errorResponsesRequiringUserInteraction = [
          'interaction_required',
          'login_required',
          'account_selection_required',
          'consent_required',
        ];

        if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
          // 3. ASK FOR LOGIN:
          // At this point we know for sure that we have to ask the
          // user to log in, so we redirect them to the IdServer to
          // enter credentials.
          //
          // Enable this to ALWAYS force a user to login.
          // this.login();
          //
          // Instead, we'll now do this:
          console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
        }

        // We can't handle the truth, just pass on the problem to the
        // next handler.
        throw result;
      }
    } catch (err) {
      this.isDoneLoadingSubject$.next(true);
    }
    this.isDoneLoadingSubject$.next(true);
    return;
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public async logout(remote?: boolean): Promise<void> {
    if(remote) {
      await this.oauthService.revokeTokenAndLogout();
    }
    else {
      await this._logout();
    }
    this.clearStorage();

    return;
  }

  private async _logout() {
    this.oauthService.revokeTokenAndLogout();
    // this.navigateToLoginPage();
    this.oauthService.logOut();
    //this.login('/');
  }

  public refresh() {
    this.oauthService.refreshToken();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  private clearStorage() {
    [
      'access_token_stored_at',
      'id_token_expires_at',
      'nonce',
      'session_state',
      'id_token',
      'PKCE_verifier',
      'access_token',
      'id_token_claims_obj',
      'refresh_token',
      'id_token_stored_at',
      'granted_scopes',
      'expires_at',
    ].forEach((key) => localStorage.removeItem(key));
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
